import { api } from '@/config/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { ExpextedResponse, CreateWithdraw, ListWithdraw, WithdrawMessage, WithdrawData, CreateRejectWithdraw, CreateApproveWithdraw } from '@/entities/Withdraw';
import { defineStore } from 'pinia';

interface State {
    message: WithdrawMessage
    data: WithdrawData[] | null;
}

export const useWithdrawStore = defineStore('WithdrawStore', {
    state: (): State => ({
        message: {
            en: '',
            id: ''
        },
        data: null,
    }),
    actions: {
        setWithdraw(payload: CreateWithdraw): Promise<AxiosResponse<ExpextedResponse>> {
            return new Promise((resolve, reject) => {
                api.post('/v1/withdraw', payload, {}).then((res: AxiosResponse<ExpextedResponse>) => {
                    resolve(res);
                }).catch((err: AxiosError) => {
                    if (err.code !== '500') {
                        this.message.id = err.response?.data.message.id
                        reject(this.message.id)
                    }
                    reject(err)
                });
            });
        },
        getListWithdraw(payload: ListWithdraw): Promise<AxiosResponse<ExpextedResponse>> {
            return new Promise((resolve, reject) => {
                const url = `/v1/withdraw?page=${payload.page}&limit=${payload.limit}`;
                api.get(url).then((res: AxiosResponse<ExpextedResponse>) => {
                    this.data = res.data.data;
                    resolve(res);
                }).catch((err: AxiosError) => {
                    if (err.code !== '500') {
                        this.message.id = err.response?.data.message.id
                        reject(this.message.id)
                    }
                    reject(err)
                });
            });
        },
        setRejectWithdraw(payload: CreateRejectWithdraw): Promise<AxiosResponse<ExpextedResponse>> {
            return new Promise((resolve, reject) => {
                api.post('/v1/withdraw/reject', payload, {}).then((res: AxiosResponse<ExpextedResponse>) => {
                    resolve(res);
                }).catch((err: AxiosError) => {
                    if (err.code !== '500') {
                        this.message.id = err.response?.data.message.id
                        reject(this.message.id)
                    }
                    reject(err)
                });
            });
        },
        setApproveWithdraw(payload: CreateApproveWithdraw): Promise<AxiosResponse<ExpextedResponse>> {
            const formData = new FormData();
            formData.append("id", String(payload.id))
            formData.append("attachment", payload.attachment)
            
            return new Promise((resolve, reject) => {
                api.post('/v1/withdraw/approve', formData, {}).then((res: AxiosResponse<ExpextedResponse>) => {
                    resolve(res);
                }).catch((err: AxiosError) => {
                    if (err.code !== '500') {
                        this.message.id = err.response?.data.message.id
                        reject(this.message.id)
                    }
                    reject(err)
                });
            });
        }
    },
    getters: {

    },
});