
import { defineComponent, ref } from 'vue';
import { AxiosResponse } from 'axios';
import { CreateWithdraw } from '@/entities/Withdraw';
import { useWithdrawStore } from '@/stores/withdraw/WithdrawStore';
import { WithdrawData, ExpextedResponse } from '@/entities/Withdraw';
import moment from 'moment'
import Storage from "@/misc/Storage";
import Alert from '@/misc/Alert';


const columns = [
  {
    name: 'Amount',
    align: 'left',
    label: 'Amount',
    field: 'Amount',
    sortable: true,
  },
  {
    name: 'Proof File',
    align: 'left',
    label: 'Proof File',
    field: 'ProofFile',
    sortable: true,
  },
  {
    name: 'Status',
    align: 'left',
    label: 'Status',
    field: 'Status',
    sortable: true,
  },
  {
    name: 'Request At',
    align: 'left',
    label: 'Creation Date',
    field: 'CreatedAt',
    sortable: true,
  },
  {
    name: 'action',
    align: 'left',
    label: 'Action',
    field: 'action',
    sortable: true,
  },
];
export default defineComponent({
  setup() {
    const withdrawStore = useWithdrawStore();
    const isAdmin = Storage.getIsAdmin()

    const filter = '';
    const withdraw = ref<CreateWithdraw>({
      amount: 0,
    });
    const mode = 'list';
    const pagination = ref({
      rowsNumber: 0,
      rowsPerPage: 10,
      page: 1,
    });
    const data = ref<WithdrawData[]>([]);

    const newCustomer = ref(false);

    const buttonNewCustomer = () => {
      newCustomer.value = !newCustomer.value;
    };

    const getListWithdraw = () => {
      const { rowsPerPage: limit, page } = pagination.value;
      withdrawStore.getListWithdraw({ page, limit }).then((res: AxiosResponse<ExpextedResponse>) => {
        if (res.data.data){
          data.value = res.data.data;
          
          for (const val of data.value) {
            val.CreatedAt = moment(val.CreatedAt).format('DD/MM/YYYY hh:mm')
          }
        }
      });
    };

    getListWithdraw();

    const onPaginationUpdate = () => {
      getListWithdraw();
    };

    const onPaginationUpdatePrev = () => {
      if (pagination.value.page > 1){
        pagination.value.page -= 1;
        getListWithdraw();
      }
    };

    const onPaginationUpdateNext = () => {
      if (data.value.length%10 === 0){
        pagination.value.page += 1;
        getListWithdraw()
      }
    };

    const createWithdraw = async () => {
      await Alert.Confirmation({
         type: "question",
         btnTxt: "Yes",
         title: "Are you sure wanna Request?",
       }).then((isReject) => {
            if (isReject) {
              withdrawStore.setWithdraw({ amount: Number(withdraw.value.amount )}).then(() => {
                Alert.Success({title: "Withdraw", message: 'Dana akan segera ditransfer'})
                getListWithdraw()
              }).catch((err) => {
                Alert.Error({title: "Withdraw", message: err})
              });
            }
        });
    };

    const rejectWithdraw = async (id: number) => {
      await Alert.Confirmation({
          type: "question",
          btnTxt: "Yes",
          title: "Are you sure wanna Reject?",
        }).then((isReject) => {
          if (isReject) {
            withdrawStore.setRejectWithdraw({ id: id }).then(() => {
              Alert.Success({title: "Withdraw", message: 'Penarikan Dana ditolak'})
                getListWithdraw()
              }).catch((err) => {
                Alert.Error({title: "Withdraw", message: err})
              });
            }
        });
    };

    const approveWithdraw = async (id: number) => {
     await Alert.ConfirmationFormFile().then(async (file)=>{
       if (file){
        await Alert.Confirmation({
          type: "question",
          btnTxt: "Yes",
          title: "Are you sure wanna Approve?",
        }).then((isApprove) => {
          if (isApprove) {
            withdrawStore.setApproveWithdraw({ id: id, attachment: file }).then(() => {
              Alert.Success({title: "Withdraw", message: 'Penarikan Dana disetujui'})
                  getListWithdraw()
                }).catch((err) => {
                  Alert.Error({title: "Withdraw", message: err})
              });
          }
        });
       }
     })
    };

    return {
      buttonNewCustomer,
      onPaginationUpdate,
      onPaginationUpdateNext,
      onPaginationUpdatePrev,
      createWithdraw,
      rejectWithdraw,
      approveWithdraw,
      filter,
      isAdmin,
      withdraw,
      newCustomer,
      mode,
      pagination,
      data,
      columns,
    };
  },
});
